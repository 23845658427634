<template>
    <div>
        <div class="project-div" v-for="(item, index) in projs" :key="index" @click="redirect(item)">

            <h6>{{ item.infra }}</h6>
            <div v-if="item.techs.length > 0">
                <div class="project"><b>Project:</b> <a href=item.url> {{ item.name }}</a></div>
                <div class="tech-used">
                    Technologies used:
                </div>
                <ul>
                    <li v-for="(techs, iTech) in item.techs" :key="iTech">
                        {{ techs }}
                    </li>
                </ul>
            </div>
            <div v-else>
                <div class="project"><b>Project:</b> No online projects </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {



    data() {
        const projs = [
            {
                name: 'Sintegra API',
                url: 'https://sintegrapi.com.br',
                infra: "AWS EC3 - Ubuntu LTS 22.04 - Nginx (IaaS)",
                techs: [
                    'C# .NET 6',
                    'Vue 3',
                    'Nuxt3',
                    'CSS',
                    'HTML'
                ]
            },
            {
                name: 'Fut del Cantão',
                url: 'https://fut.delcantao.dev',
                infra: "Vercel",
                techs: [
                    'Vue 3',
                    'CSS',
                    'HTML'
                ]
            },
            {
                name: 'Delcantao Portfolio (this website)',
                url: 'https://delcantao.dev',
                infra: "Vercel",
                techs: [
                    'C# .NET 7',
                    'Vue.js',
                    'Tailwind CSS',
                    'CSS',
                    'HTML'
                ]
            },
            {
                name: '',
                url: '',
                infra: "Heroku",
                techs: []
            },
            {
                name: '',
                url: '',
                infra: "Digital Ocean",
                techs: []
            },
            {
                name: 'offline',
                url: 'https://',
                infra: "Airbnb scraping + automatic e-mail sender & Ubuntu 22.04 - Home Server",
                techs: []
            },
            {
                name: 'Sistemas TH',
                url: 'https://sipweb.sistemasth.com.br',
                infra: "Windows Server - Microsoft IIS (IIS 6 to IIS 10) (On-Premise)",
                techs: [
                    "C# .NET Core 3.1, .NET 6 & .NET 7"
                    , "VB.NET .NET Framework 2.0 - 4.8"
                    , "PHP 5, 6, 7"
                    , "Wordpress"
                    , "Python 2.7 & 3"
                    , "Java & Kotlin"
                    , "Vue.js"
                    , "Javascript"
                    , "CSS"
                    , "HTML"

                ]
            },
            {
                name: 'Think Data',
                url: 'https://thinkweb.thinkdata.com.br',
                infra: "Windows Server - Microsoft IIS (IIS 6 to IIS 10) (On-Premise)",
                techs: [
                    "C# .NET Core 3.1, .NET 6 & .NET 7"
                    , "VB.NET .NET Framework 2.0 - 4.8"
                    , "PHP 5, 6, 7"
                    , "Python 2.7 & 3"
                    , "Javascript"
                    , "CSS"
                    , "HTML"

                ]
            }


        ]
        console.log('count', projs[0].techs.length)
        return { projs }
    }, methods: {
        redirect(item) {
            if (item?.url === '')
                return
            window.open(item.url, "_blank")
        }
    }

}
</script>
<style lang="css">
.project-div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 100%;
    max-width: 1000px;
    padding: 0 20px;
    border: 1px solid #3f6cbd;
    border-radius: 5px;
    padding: 1.5rem;
    margin-bottom: 10px;
    max-height: 450px;
    min-height: 300px;
    text-align: center;

}

.project-div:hover {
    background-color: #fff;
    color: #3f6cbd !important;
    cursor: pointer;
}

.project-div h6 {
    width: 100%;
    text-align: center;
    font-size: 1.2rem;
    font-weight: 600;
    margin: 0;
    padding: 0;
    color: #3f6cbd !important;
}

.project-div ul {
    width: 100%;
    margin: 0;
    padding: 0;
}

.project-div ul li {
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center;
}

.project-div a {
    color: #000;
    text-decoration: underline;
}
</style>
