<template>
  <div class="delcantao_tm_home">
    <div class="home_content">
      <div class="avatar">
        <div class="image avatar_img" :style="{ backgroundImage: 'url(' + src1 + ')' }"></div>
      </div>
      <!-- End .avatar -->
      <div class="details">
        <h3 class="name">Fernando del Cantão</h3>
        <p class="job">
          Software Developer with Diverse Abilities - I'm passionate about the entire workflow that drives software
          functionality, from conception to execution.
        </p>

        <Social />
      </div>
      <!-- End .details -->
    </div>
  </div>
</template>

<script>
import Social from "./Social";
export default {
  components: {
    Social,
  },
  data() {
    return {
      src1: require("../assets/img/slider/1.jpg"),
    };
  },
};
</script>

<style lang="scss"></style>
