<template>
  <div class="delcantao_tm_about">
    <div class="about_image">
      <img src="../assets/img/slider/me-montains.jpg" alt="about" />
    </div>
    <!-- End .about_image -->

    <div class="description">
      <h3 class="name">Fernando del Cantão | Software Developer</h3>
      <div class="description_inner">
        <div class="left">
          <p>
            Hello, welcome to my website.
          </p>
          <p>
            I am a Brazilian software developer based in Switzerland, an expert in .NET stack with solid knowledge in web
            development mainly as a backend.
          </p>
          Other technologies that I love to deal with are Vue, Nuxt, PHP, VB.NET, Java, HTML, CSS, Node.js, SQL Server.
          <p>
            I have experience in hosting applications in diverse infrastructures, On-Premise, IaaS or PaaS:
          </p>

          <p> Welcome to my website! </p>

          <p>I am a highly skilled Brazilian software developer currently residing in Switzerland, specializing in the
            .NET stack with a strong focus on backend web development. Alongside my expertise in .NET, I am passionate
            about working with a variety of technologies, including Vue, Nuxt, PHP, VB.NET, Java, HTML, CSS, Node.js, and
            SQL Server.</p>


          <p> Throughout my career, I have successfully hosted applications on diverse infrastructures, such as
            On-Premise, IaaS, and PaaS environments. This experience has provided me with a deep understanding of handling
            different hosting solutions. </p>

          <p>As a software enthusiast, I have had the opportunity to administer various applications, both in the past and
            currently. </p>

          <p> Feel free to explore my website to learn more about my projects, skills, and professional journey. If you
            have any inquiries or would like to discuss potential collaborations, please don't hesitate to get in touch.
          </p>

          <p>Thank you for visiting! </p>

          <p>Some application that I am or were Administrator</p>


          <Projects />

          <div>I am happy to travel all over the world to know new cultures and people. I love animals.</div>

          <div class="delcantao_tm_button">
            <button class="ib-button" @click="showModal">Read More</button>
          </div>
        </div>
        <!-- End .left -->

        <div class="right">
          <ul>
            <li>
              <p><span>Birthday:</span>07.08.1991</p>
            </li>
            <li>
              <p><span>Age:</span>{{ calculateAge('1991-08-07') }}</p>
            </li>
            <li>
              <p><span>Address:</span>Reussinsel 24, Luzern, Switzerland</p>
            </li>
            <li>
              <p>
                <span>Email:</span><a href="mailto:accounting@delcantao.dev">accounting@delcantao.dev</a>
              </p>
            </li>
            <li>
              <p>
                <span>Phone:</span><a href="tel:+44765629227">00 41 76 562 9227</a>
              </p>
            </li>
            <li>
              <p><span>Study:</span>FATECSP</p>
            </li>
            <li>
              <p><span>Freelance:</span>Available</p>
            </li>
          </ul>
          <!-- End ul -->
        </div>
        <!-- End .right -->
      </div>
    </div>

    <!-- Start Modalbox -->
    <transition name="fade">
      <!-- Modal -->
      <div :class="{ 'modal-mask': isActive }" id="modal" @click="closeModal" v-if="isVisible">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-user" @click.stop>
          <div class="modal-content">
            <div class="delcantao_tm_modalbox_about">
              <div class="close" @click="closeModal">
                <img class="svg" src="../assets/img/svg/cancel.svg" alt="cancel-img" />
              </div>
              <!-- End .close -->
              <div class="my_box">
                <div class="left">
                  <div class="about_title">
                    <h3>Development Skills</h3>
                  </div>
                  <!-- End .abut_title -->
                  <div class="delcantao_progress">
                    <div class="progress_inner">
                      <span><span class="label">Backend Development</span><span class="number">95%</span></span>
                      <div class="background">
                        <div class="bar">
                          <div class="bar_in" style="width:95%"></div>
                        </div>
                      </div>
                    </div>
                    <!-- End progress_inner -->
                    <div class="progress_inner">
                      <span><span class="label">Database</span><span class="number">95%</span></span>
                      <div class="background">
                        <div class="bar">
                          <div class="bar_in" style="width:95%"></div>
                        </div>
                      </div>
                    </div>
                    <!-- End progress_inner -->
                    <div class="progress_inner">
                      <span><span class="label">Frontend Development</span><span class="number">60%</span></span>
                      <div class="background">
                        <div class="bar">
                          <div class="bar_in" style="width:60%"></div>
                        </div>
                      </div>
                    </div>
                    <!-- End progress_inner -->
                  </div>
                  <!-- End .delcantao_progress -->
                </div>
                <!-- End .left -->
                <div class="right">
                  <div class="about_title">
                    <h3>Language Skills</h3>
                  </div>
                  <div class="delcantao_progress">
                    <div class="progress_inner">
                      <span><span class="label">Portuguese</span><span class="number">100%</span></span>
                      <div class="background">
                        <div class="bar">
                          <div class="bar_in" style="width:100%"></div>
                        </div>
                      </div>
                    </div>
                    <div class="progress_inner">
                      <span><span class="label">English</span><span class="number">80%</span></span>
                      <div class="background">
                        <div class="bar">
                          <div class="bar_in" style="width:80%"></div>
                        </div>
                      </div>
                    </div>
                    <div class="progress_inner">
                      <span><span class="label">German</span><span class="number">50%</span></span>
                      <div class="background">
                        <div class="bar">
                          <div class="bar_in" style="width:50%"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- End .right -->
              </div>
              <div class="counter">
                <div class="about_title">
                  <h3>Fun Facts</h3>
                </div>
                <ul>
                  <li>
                    <div class="list_inner">
                      <h3>44+</h3>
                      <span>Projects Completed</span>
                    </div>
                  </li>
                  <li>
                    <div class="list_inner">
                      <h3>4</h3>
                      <span>Happy Clients</span>
                    </div>
                  </li>
                  <li>
                    <div class="list_inner">
                      <h3>158K+</h3>
                      <span>Lines of Code</span>
                    </div>
                  </li>
                </ul>
                <!-- End ul -->
              </div>
              <!-- End .counter -->
              <div class="partners">
                <div class="about_title">
                  <h3>Our Partners</h3>
                </div>
                <Brand />
              </div>
              <!-- End .partner -->
            </div>
            <!-- End .delcantao_tm_modalbox_about -->
          </div>
        </div>
      </div>
    </transition>
    <!-- End Modalbox -->
  </div>
</template>

<script>
import Brand from "./Brand";
import Projects from "./Projects";
export default {
  components: {
    Brand,
    Projects
  },
  data() {
    return {
      isActive: false,
      isVisible: false,
    };
  },
  methods: {
    showModal: function () {
      this.isActive = true;
      this.isVisible = true;
    },
    closeModal: function () {
      this.isActive = false;
      this.isVisible = false;
    },
    calculateAge(birthday) {
      let birthdayDate = new Date(birthday);
      let ageDifMs = Date.now() - birthdayDate.getTime();
      let ageDate = new Date(ageDifMs);
      return Math.abs(ageDate.getUTCFullYear() - 1970);
    }
  },
};
</script>

